.header {
  position: relative;
  height: 60px;
  width: 100%;
  left: 0;
  top: 0;
  padding: 10px 30px;
  background: #343a40;
  color: white;
}

.logo {
  position: relative;
  display: inline-block;
  margin-top: 4px;
  height: 16px;
}

.logo img {
  height: 100%;
}

.header-text {
  position: absolute;
  display: inline-block;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 1.1em;
}

.logout-button {
  position: relative;
  float: right;
}
