.modal-content {
  margin-top: 100px;
}

.customer-selection {
    margin-left: 10px;
}

.parameter-selection-container {
    margin: 10px 0px;
}

.error {
    color: red;
}
