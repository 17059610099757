.csv-selection-container {
  margin-bottom: 10px;
}

.section-label {
  margin-right: 5px;
  font-weight: bold;
}

.selection-notes {
  font-size: 0.85em;
  margin-left: 5px;
}

.error {
  color: red;
}
