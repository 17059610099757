.invoicing-area {
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  margin-top: 10px;
  text-align: right;
}

.button-container {
  margin-right: 10px;
  display: inline-block;
}
