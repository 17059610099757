.footer {
  position: relative;
  height: 40px;
  width: 100%;
  left: 0;
  top: 0;
  padding: 10px 30px;
  background: #343a40;
  color: white;
}
